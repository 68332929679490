import * as React from "react";

// Import Swiper React components
import { FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { isDesktop } from "react-device-detect";
import { StaticImage } from "gatsby-plugin-image";

const PhoneSlider = () => {
  return (
    <div className="max-w-6xl py-16 mx-auto">
      <Swiper
        modules={[FreeMode]}
        freeMode={true}
        grabCursor={true}
        spaceBetween={10}
        centeredSlides={true}
        className={`relative ${
          isDesktop ? " !overflow-visible" : " overflow-hidden"
        }`}
      >
        <SwiperSlide>
          <div className=" w-[280px]">
            <div className="flex flex-col">
              <div>
                <StaticImage
                  alt="katsana"
                  src="../../static/images/phones/1-vehicle-movement-alert.webp"
                  objectFit="cover"
                  className="inset-0 -ml-10 "
                />
              </div>
              <div className="px-8 py-4 mt-2 text-white">
                <h5 className="text-sm font-medium">
                  Sudden vehicle movement alerts
                </h5>
                <p className="mt-2 text-sm">
                  Get notified via app as soon as your vehicle initiate a trip,
                  whether you are away on vacation, at work, or at leisure. Be
                  warned against possible theft.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className=" w-[280px]">
            <div className="flex flex-col">
              <div>
                <StaticImage
                  alt="katsana"
                  src="../../static/images/phones/2-share-car-location.webp"
                  objectFit="cover"
                  className="inset-0 -ml-10 "
                />
              </div>
              <div className="px-8 py-4 mt-2 text-white">
                <h5 className="text-sm font-medium">
                  Share live car location to family, friends & police
                </h5>
                <p className="mt-2 text-sm">
                  Keep track of family members on the road and be notified when
                  they have safely arrived to a destination you’ve marked. In
                  emergencies, share the live location to people you choose.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className=" w-[280px]">
            <div className="flex flex-col">
              <div>
                <StaticImage
                  alt="katsana"
                  src="../../static/images/phones/3-track-multiple-vehicles.webp"
                  objectFit="cover"
                  className="inset-0 -ml-10 "
                />
              </div>
              <div className="px-8 py-4 mt-2 text-white">
                <h5 className="text-sm font-medium">
                  Track multiple vehicles in real-time
                </h5>
                <p className="mt-2 text-sm">
                  With instantaneous update of vehicle position and movement,
                  you will never miss a turn. Choose to monitor all or specific
                  family vehicles from the convenience of a mobile app
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className=" w-[280px]">
            <div className="flex flex-col">
              <div>
                <StaticImage
                  alt="katsana"
                  src="../../static/images/phones/4-gps-travel-hisotry.webp"
                  objectFit="cover"
                  className="inset-0 -ml-10 "
                />
              </div>
              <div className="px-8 py-4 mt-2 text-white">
                <h5 className="text-sm font-medium">
                  Longest data history in the industry
                </h5>
                <p className="mt-2 text-sm">
                  Vehicle movement history is stored for one whole year,
                  allowing you to review past trips with precision. KATSANA
                  offers the longest data storage in the industry as a standard
                  offering.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className=" w-[280px]">
            <div className="flex flex-col">
              <div>
                <StaticImage
                  alt="katsana"
                  src="../../static/images/phones/5-immobilizer.webp"
                  objectFit="cover"
                  className="inset-0 -ml-10 "
                />
              </div>
              <div className="px-8 py-4 mt-2 text-white">
                <h5 className="text-sm font-medium">
                  Shut down the vehicle in emergency situation
                </h5>
                <p className="mt-2 text-sm">
                  In emergencies, you can immobilise the vehicle using KATSANA’s
                  highly secure vehicle immobiliser feature available on the
                  KATSANA car tracking app.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className=" w-[280px]">
            <div className="flex flex-col">
              <div>
                <StaticImage
                  alt="katsana"
                  src="../../static/images/phones/6-gps-tracker-notifications.webp"
                  objectFit="cover"
                  className="inset-0 -ml-10 "
                />
              </div>
              <div className="px-8 py-4 mt-2 text-white">
                <h5 className="text-sm font-medium">
                  Low battery & Car battery removed alerts
                </h5>
                <p className="mt-2 text-sm">
                  KATSANA vehicle tracking system continuously monitors the
                  health of your car battery. When it detects a low car battery,
                  you will get alerted - saving you from sudden inoperable car.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default PhoneSlider;
