import * as React from "react";

// Import Swiper React components
import { FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { isDesktop } from "react-device-detect";
import { StaticImage } from "gatsby-plugin-image";

const ReviewSlider = () => {
  return (
    <div className="max-w-6xl py-16 mx-auto ">
      <Swiper
        modules={[FreeMode]}
        freeMode={true}
        grabCursor={true}
        spaceBetween={30}
        centeredSlides={true}
        className={`relative ${
          isDesktop ? " !overflow-visible" : " overflow-hidden"
        }`}
      >
        <SwiperSlide>
          <div className="w-72 px-4 py-6 bg-white rounded md:px-6 md:py-8 md:w-[360px] ml-8 md:ml-0">
            <div className="flex items-center min-h-[110px] ">
              <div className="relative ">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/avatar/review1.jpg"
                  objectFit="cover"
                  className="inset-0 w-12 h-12 rounded-full md:w-20 md:h-20"
                />
              </div>
              <div className="relative ml-4 text-center">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/graphics/5stars.jpg"
                  objectFit="cover"
                  className="inset-0 w-[180px] h-[49px]"
                />
                <p className="text-sm text-bluetext">
                  “Very reliable GPS Fleet system”
                </p>
              </div>
            </div>
            <h5 className="my-2 mt-4 text-xl font-semibold leading-none">
              Cheong Mun Yuen
            </h5>
            <p className="text-sm leading-none ">
              Fleet Manager, Kudrat Malaysia
            </p>
            <p className="mt-6 text-sm">
              KATSANA® GPS Fleet has been a great addition to our fleet of
              vehicles.
              <br />
              <br />
              Apart from enabling us to identify risky drivers, we have been
              able to <strong>recover over 40 missing vehicles</strong> from
              hire-purchase debtors; all with
              <strong>100% recovery rate</strong> of missing vehicles.
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="w-72 px-4 py-6 bg-white rounded md:px-6 md:py-8 md:w-[360px]">
            <div className="flex items-center min-h-[110px] ">
              <div className="relative ">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/avatar/review2.jpg"
                  objectFit="cover"
                  className="inset-0 w-12 h-12 rounded-full md:w-20 md:h-20"
                />
              </div>
              <div className="relative ml-4 text-center">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/graphics/5stars.jpg"
                  objectFit="cover"
                  className="inset-0 w-[180px] h-[49px]"
                />
                <p className="text-sm text-bluetext">
                  “Helped us find RM1.2 million of stolen asset.”
                </p>
              </div>
            </div>
            <h5 className="my-2 mt-4 text-xl font-semibold leading-none">
              Ms Siah
            </h5>
            <p className="text-sm leading-none ">
              Manager, WCM Machinery Sdn Bhd
            </p>
            <p className="mt-6 text-sm">
              KATSANA® GPS Tracking & Fleet Management System allowed WCM
              Machinery to{" "}
              <strong>
                recover a stolen lorry carrying multi-million dollar electric
                generator
              </strong>{" "}
              in under an hour.
              <br />
              <br />
              Whats more, we have helped the police to recover another truck
              that was also stolen & <strong>nabbed the thieves</strong> in the
              vicinity.
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="w-72 px-4 py-6 bg-white rounded md:px-6 md:py-8 md:w-[360px]">
            <div className="flex items-center min-h-[110px] ">
              <div className="relative ">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/avatar/review3.jpg"
                  objectFit="cover"
                  className="inset-0 w-12 h-12 rounded-full md:w-20 md:h-20"
                />
              </div>
              <div className="relative ml-4 text-center">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/graphics/5stars.jpg"
                  objectFit="cover"
                  className="inset-0 w-[180px] h-[49px]"
                />
                <p className="text-sm text-bluetext">
                  “KATSANA GPS tracker found my stolen Honda Civic Type-R”
                </p>
              </div>
            </div>
            <h5 className="my-2 mt-4 text-xl font-semibold leading-none">
              D. Basir.
            </h5>
            <p className="text-sm leading-none ">
              Car owner, Honda Civic Type-R
            </p>
            <p className="mt-6 text-sm">
              KATSANA car tracking system is{" "}
              <strong>the best investment ever for my car.</strong> When I
              received the alert that my car is stolen, I did not believe at
              first. I quickly check the KATSANA live tracking app, OH MY GOD my
              car was really stolen!
              <br />
              <br />
              Luckily I have KATSANA car tracker that tracked the car back.{" "}
              <strong>It’s in the news!</strong>
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="w-72 px-4 py-6 bg-white rounded md:px-6 md:py-8 md:w-[360px]">
            <div className="flex items-center min-h-[110px] ">
              <div className="relative ">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/avatar/review4.jpg"
                  objectFit="cover"
                  className="inset-0 w-12 h-12 rounded-full md:w-20 md:h-20"
                />
              </div>
              <div className="relative ml-4 text-center">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/graphics/5stars.jpg"
                  objectFit="cover"
                  className="inset-0 w-[180px] h-[49px]"
                />
                <p className="text-sm text-bluetext">
                  “I can now sleep easily at night”
                </p>
              </div>
            </div>
            <h5 className="my-2 mt-4 text-xl font-semibold leading-none">
              Helmy H.
            </h5>
            <p className="text-sm leading-none ">Car owner, Subaru WRX</p>
            <p className="mt-6 text-sm">
              <strong>I am very satisfied</strong> with KATSANA vehicle tracking
              system.
              <br />
              <br />
              Based on my own research and advise from other Subaru friends, I
              decided to install this car security & GPS tracker system on my
              Subaru WRX and{" "}
              <strong>this helped me sleep soundly at night.</strong>
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="w-72 px-4 py-6 bg-white rounded md:px-6 md:py-8 md:w-[360px]">
            <div className="flex items-center min-h-[110px] ">
              <div className="relative ">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/avatar/review5.jpg"
                  objectFit="cover"
                  className="inset-0 w-12 h-12 rounded-full md:w-20 md:h-20"
                />
              </div>
              <div className="relative ml-4 text-center">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/graphics/5stars.jpg"
                  objectFit="cover"
                  className="inset-0 w-[180px] h-[49px]"
                />
                <p className="text-sm text-bluetext">
                  “So glad I have this car tracker. 3 cars recovered when my
                  house was robbed.”
                </p>
              </div>
            </div>
            <h5 className="my-2 mt-4 text-xl font-semibold leading-none">
              M. Adiwira S.
            </h5>
            <p className="text-sm leading-none ">Car owner, Multiple cars</p>
            <p className="mt-6 text-sm">
              You heard it right. My house was robbed back in December 2021.
              <br />
              <br />
              The robbers took all my cars. Im thankful and glad I made the
              decision to install KATSANA to track my cars .
              <br />
              <br />
              <strong>All the cars were recovered in just 3 hours.</strong>
              Im very, very, very thanful.
            </p>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default ReviewSlider;
