import * as React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { StaticImage } from "gatsby-plugin-image";

const GPSTab = () => {
  return (
    <div className="max-w-6xl px-8 py-16 mx-auto md:px-0">
      <Tabs
        className="grid grid-cols-2 gap-12 cursor-pointer"
        selectedTabClassName="bg-[hsla(210,44%,16%,1)] rounded border-l-2 border-blue-800"
        forceRenderTabPanel
      >
        <div className="relative z-10 col-span-1">
          <TabList className="flex flex-col mt-4 text-bluegray">
            <Tab className="py-[1rem] px-[4rem] mt-4 transition-transform duration-700 transform hover:-translate-y-1">
              <h5 id="oil-gas" className="font-medium text-white">
                Sudden vehicle movement alerts
              </h5>
              <p className="text-sm">
                Get notified via app as soon as your vehicle initiate a trip,
                whether you are away on vacation, at work, or at leisure. Be
                warned against possible theft.
              </p>
            </Tab>
            <Tab className="py-[1rem] px-[4rem] transition-transform duration-700 transform hover:-translate-y-1">
              <h5 id="seaports" className="font-medium text-white">
                Share live car location to family, friends & police
              </h5>
              <p className="text-sm">
                Keep track of family members on the road and be notified when
                they have safely arrived to a destination you’ve marked. In
                emergencies, share the live location to people you choose.
              </p>
            </Tab>
            <Tab className="py-[1rem] px-[4rem] transition-transform duration-700 transform hover:-translate-y-1">
              <h5 id="agriculture" className="font-medium text-white">
                Track multiple vehicles in real-time
              </h5>
              <p className="text-sm">
                With instantaneous update of vehicle position and movement, you
                will never miss a turn. Choose to monitor all or specific family
                vehicles from the convenience of a mobile app
              </p>
            </Tab>
            <Tab className="py-[1rem] px-[4rem] transition-transform duration-700 transform hover:-translate-y-1">
              <h5 id="public-works" className="font-medium text-white">
                Longest data history in the industry
              </h5>
              <p className="text-sm">
                Vehicle movement history is stored for one whole year, allowing
                you to review past trips with precision. KATSANA offers the
                longest data storage in the industry as a standard offering.
              </p>
            </Tab>
            <Tab className="py-[1rem] px-[4rem] transition-transform duration-700 transform hover:-translate-y-1">
              <h5 id="public-works" className="font-medium text-white">
                Shut down the vehicle in emergency situation
              </h5>
              <p className="text-sm">
                In emergencies, you can immobilise the vehicle using KATSANA’s
                highly secure vehicle immobiliser feature available on the
                KATSANA car tracking app.
              </p>
            </Tab>
            <Tab className="py-[1rem] px-[4rem] transition-transform duration-700 transform hover:-translate-y-1">
              <h5 id="public-works" className="font-medium text-white">
                Low battery & Car battery removed alerts
              </h5>
              <p className="text-sm">
                KATSANA vehicle tracking system continuously monitors the health
                of your car battery. When it detects a low car battery, you will
                get alerted - saving you from sudden inoperable car.
              </p>
            </Tab>
          </TabList>
        </div>
        <div className="col-span-1">
          <TabPanel>
            <StaticImage
              alt="katsana"
              src="../../static/images/phones/1-vehicle-movement-alert.webp"
              objectFit="contain"
              className=" w-[552px] h-[667px] left-[-100px]"
            />
          </TabPanel>
          <TabPanel>
            <StaticImage
              alt="katsana"
              src="../../static/images/phones/2-share-car-location.webp"
              objectFit="contain"
              className=" w-[552px] h-[667px] left-[-100px]"
            />
          </TabPanel>
          <TabPanel>
            <StaticImage
              alt="katsana"
              src="../../static/images/phones/3-track-multiple-vehicles.webp"
              objectFit="contain"
              className=" w-[552px] h-[667px] left-[-100px]"
            />
          </TabPanel>
          <TabPanel>
            <StaticImage
              alt="katsana"
              src="../../static/images/phones/4-gps-travel-hisotry.webp"
              objectFit="contain"
              className=" w-[552px] h-[667px] left-[-100px]"
            />
          </TabPanel>
          <TabPanel>
            <StaticImage
              alt="katsana"
              src="../../static/images/phones/5-immobilizer.webp"
              objectFit="contain"
              className=" w-[552px] h-[667px] left-[-100px]"
            />
          </TabPanel>
          <TabPanel>
            <StaticImage
              alt="katsana"
              src="../../static/images/phones/6-gps-tracker-notifications.webp"
              objectFit="contain"
              className=" w-[552px] h-[667px] left-[-100px]"
            />
          </TabPanel>
        </div>
      </Tabs>
    </div>
  );
};

export default GPSTab;
