import * as React from "react";
import { Helmet } from "react-helmet";
import LazyLoad from "react-lazyload";
import { MobileView, BrowserView } from "react-device-detect";
import { StaticImage } from "gatsby-plugin-image";
import CountUp from "react-countup";
import Layout from "../components/layout";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import ReviewSlider from "../components/reviewslider";
import Cta from "../components/cta";
import GPSTab from "../components/GPStab";
import PhoneSlider from "../components/phoneslider";
import CaseSlider from "../components/caseslider";
import * as Styles from "../styles/success-stories.module.css";
import Video from "../components/video";

const GPSTracker = () => {
  return (
    <ParallaxProvider>
      <Layout pageTitle="Home Page">
        {/* MetaTag */}
        <Helmet
          htmlAttributes={{
            lang: "en",
          }}
        >
          <title>
            GPS Tracker Malaysia by KATSANA® Integrated Fleet Management
            Solutions
          </title>
          <meta
            name="description"
            content="Malaysia's most Advanced GPS Tracking System to track, monitor & secure cars against theft."
          />
          <meta
            name="robots"
            content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          />
          <link
            rel="canonical"
            href="https://www.katsana.com/gps-tracker-malaysia"
          />

          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content="https://www.katsana.com/gps-tracker-malaysia"
          />
          <meta
            property="og:title"
            content="GPS Tracker Malaysia by KATSANA® Integrated Fleet Management Solutions"
          />
          <meta
            property="og:description"
            content="Malaysia's most Advanced GPS Tracking System to track, monitor & secure cars against theft."
          />
          <meta
            property="og:image"
            content="https://www.katsana.com/og-image/twt_ogimage_large.jpg"
          />
          <meta
            property="og:image:alt"
            content="KATSANA Fleet Management & Industrial IoT Sensors Expert"
          />
          <meta property="og:image:width" content="1280" />
          <meta property="og:image:height" content="640" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@katsanagps" />
          <meta name="twitter:creator" content="@katsanagps" />

          <meta
            name="twitter:image"
            content="https://www.katsana.com/og-image/twt_ogimage_large.jpg"
          />

          <script type="application/ld+json">
            {`
              [
                {
                  "@context": "https://schema.org/",
                  "@type": "Product",
                  "name": "GPS Tracker Malaysia by Katsana",
                  "image": [
                    "https://shop.katsana.com/wp-content/uploads/2020/05/OBD-Go-Basic_1024x1024_2x-1-300x300.jpg",
                    "https://shop.katsana.com/wp-content/uploads/2020/05/motor-tracker-300x300.jpg",
                    "https://shop.katsana.com/wp-content/uploads/2020/05/covert-gps-tracker-300x300.jpg"
                  ],
                  "description": "The Best Car GPS Tracker to protect your vehicles. Track and secure your car using proven GPS tracking system with the highest recovery of stolen cars in Malaysia. KATSANA® Track has successfully recovered more than 98% of stolen car cases.",
                  "brand": {
                    "@type": "Brand",
                    "name": "KATSANA®"
                  },
                  "review": [
                    {
                      "@type": "Review",
                      "author": {
                        "@type": "Person",
                        "name": "D. Basir"
                      },
                      "datePublished": "2022-04-02",
                      "name": "Car owner, Honda Civic Type-R",
                      "reviewBody": "KATSANA car tracking system is the best investment ever for my car. When I received the alert that my car is stolen, I did not believe at first. I quickly check the KATSANA live tracking app, OH MY GOD my car was really stolen! Luckily I have KATSANA car tracker that tracked the car back. It’s in the news!",
                      "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                      }
                    },
                    {
                      "@type": "Review",
                      "author": {
                        "@type": "Person",
                        "name": "Helmy H."
                      },
                      "datePublished": "2022-02-15",
                      "name": "Car owner, Subaru WRX",
                      "reviewBody": "I am very satisfied with Katsana vehicle GPS tracker. Based on my own research and advise from other Subaru owners, I decided to install this car secury/GPS tracker and this helped me sleep soundly at night.",
                      "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                      }
                    },
                    {
                      "@type": "Review",
                      "author": {
                        "@type": "Person",
                        "name": "M Adiwira S."
                      },
                      "datePublished": "2021-12-26",
                      "name": "Car owner, Multiple cars",
                      "reviewBody": "So glad to have this car GPS tracker. 3 cars recovered when my house was robbed. The robbers took all my cars. All the cars were recovered in just 3 hours.",
                      "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                      }
                    },
                    {
                      "@type": "Review",
                      "author": {
                        "@type": "Person",
                        "name": "Munirah Sulaiman"
                      },
                      "datePublished": "2022-02-23",
                      "name": "Perodua Myvi",
                      "reviewBody": "Saya sangat suka car GPS tracker Katsana sebab mudah untuk saya track family members untuk keselamatan. Ye lah, ayah saya kadang-kadang boleh lupa jalan balik rumah. Saya bersyukur ada Katsana GPS tracker.",
                      "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                      }
                    },
                    {
                      "@type": "Review",
                      "author": {
                        "@type": "Person",
                        "name": "Ms. Siah"
                      },
                      "datePublished": "2022-04-02",
                      "name": "Manager, WCM Machinery Sdn Bhd",
                      "reviewBody": "This GPS car tracker recovers our company's stolen lorry carrying multi-million dollar electric generator in under an hour.",
                      "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "5",
                        "bestRating": "5"
                      }
                    }
                  ],
                  "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.9",
                    "reviewCount": "689"
                  }
                },
                {
                  "@context": "https://schema.org",
                  "@type": "FAQPage",
                  "mainEntity": [
                    {
                      "@type": "Question",
                      "name": "Do you have any promotion for the GPS tracker?",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Yes, we are currently running a promotion with significant discount of the GPS tracker. Please visit our Shop section for more information."
                      }
                    },
                    {
                      "@type": "Question",
                      "name": "What is your refund policy?",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "KATSANA® stands by quality and utmost customer satisfaction. If you are not satisfied with purchase of KATSANA® vehicle tracking system, we will provide refund within 30-days of purchase. No questions asked."
                      }
                    },
                    {
                      "@type": "Question",
                      "name": "After purchase, how do I install the GPS tracker?",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "KATSANA will arrange for professional fitment of the GPS tracker at the HQ in Kuala Lumpur (FREE installation), or at any location of your convenience (may be subjected to minimal fees)."
                      }
                    },
                    {
                      "@type": "Question",
                      "name": "Do I get alert when my car moves?",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Yes, Katsana GPS Tracking app on smartphones will send you alerts when the car moves or exits predefined places."
                      }
                    },
                    {
                      "@type": "Question",
                      "name": "Do I get alert when my car battery is low?",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Yes, Katsana GPS Tracking app on smartphones will send you alerts when it detects that your car battery is low."
                      }
                    },
                    {
                      "@type": "Question",
                      "name": "Do I need to pay monthly fees to use the GPS tracking service?",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Purchase of GPS tracker by KATSANA comes with one year network (SIM) connectivity, one year customer support and one year device warranty. Renewal of the car tracking service is on annual basis at minimal fee for network connectivity, online service, support & system maintenance. In short, no monthly subscription is needed - the annual subscription already covers everything."
                      }
                    },
                    {
                      "@type": "Question",
                      "name": "Can I relocate the GPS tracking device to a new car?",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Yes, you may relocate the car tracking device to a new car, subject to a relocation fee."
                      }
                    },
                    {
                      "@type": "Question",
                      "name": "Can I install the GPS tracker on my own, or at my preferred workshop?",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Yes, you certainly can. Should you need to install the tracking unit on your own, KATSANA will provide you with an installation instruction."
                      }
                    }
                  ]
                }
              ]            
          `}
          </script>
        </Helmet>
        {/* Hero Area */}
        <div className="relative p-8 pb-20 overflow-hidden md:p-0 md:pt-32 md:pb-48">
          <div className="absolute bottom-0 left-0 w-full">
            <div
              className={
                Styles.tilt +
                " after:bg-[hsla(213,56%,19%,1)] m-[-1px] after:h-[4vh] md:after:h-[6vh] z-20 "
              }
            ></div>
          </div>

          <div className="absolute bottom-0 left-0 w-full">
            <div
              className={
                Styles.tilt +
                " after:bg-[hsla(0,0%,0%,0.2)] m-[-1px] after:h-[4vh] md:after:h-[12vh] z-10 "
              }
            ></div>
          </div>

          <StaticImage
            alt="katsana"
            src="../../static/images/photos/gpstracker-hero.jpg"
            objectFit="cover"
            className="!absolute inset-0"
          />

          <div className="relative z-10 max-w-6xl mx-auto">
            <h3 className="max-w-[200px] pt-24 md:pt-12 md:mx-auto text-sm text-bluegray md:text-base md:max-w-none">
              Real-time GPS tracker and Fleet Management System
            </h3>
            <h1 className="max-w-4xl pt-4 text-4xl font-bold tracking-wide text-white md:pt-2 md:leading-tight md:mt-0 md:text-6xl">
              The Best Car GPS Tracker to protect your vehicles
            </h1>
            <p className="max-w-2xl mt-12 text-sm leading-6 tracking-wide md:mt-8 text-bluegray md:leading-9 md:text-xl">
              Track and secure your car using proven GPS tracking system with
              the highest recovery of stolen cars in Malaysia. KATSANA® Track
              has successfully recovered more than 98% of stolen car cases.
            </p>

            <div className="justify-start max-w-xl mt-12 md:flex md:items-center md:mt-20">
              <a
                href="https://apps.katsana.com/get-quotation/"
                className="inline px-5 py-2 font-semibold text-white rounded-full md:flex md:self-start md:px-6 md:py-3 md:text-base leading-0 bg-primary"
              >
                Buy Now
              </a>
              <a
                href="https://apps.katsana.com/get-quotation/"
                className="block mt-4 text-xs font-semibold text-white md:flex md:text-sm md:text-primary md:ml-4 md:mt-0"
                target="_blank"
                rel="noopener noreferrer"
              >
                or contact us for information
              </a>
            </div>
          </div>
        </div>

        <div className="relative bg-gradient-to-b from-[hsla(213,56%,19%,1)] to-[hsla(213,47%,17%,1)] pb-24">
          <div className="absolute bottom-0 left-0 w-full">
            <div
              className={
                Styles.tilt +
                " after:bg-[hsla(216,59%,30%,1)] m-[-1px] after:h-[4vh] md:after:h-[10vh] z-20 "
              }
            ></div>
          </div>

          <div className="max-w-6xl px-8 py-16 mx-auto lg:pb-32 xl:px-0">
            <div className="flex flex-col justify-between md:items-center md:flex-row">
              <div className="pr-10 md:w-3/5">
                <h3 className="text-4xl font-bold text-white">
                  Proven Recovery of Stolen Cars
                </h3>
                <p className="mt-8 text-sm leading-6 text-bluegray md:text-base md:leading-8">
                  KATSANA vehicle tracking system has recovered hundreds of
                  stolen vehicles in Malaysia. Our proven car tracking system
                  monitors your vehicles in real-time and notifies you of any
                  movements.
                </p>

                <div
                  className="flex flex-col my-8 md:mb-0 md:flex-row"
                  data-sal="slide-up"
                  data-sal-delay="0"
                  data-sal-easing="ease-in"
                >
                  <div>
                    <h3 className="text-4xl font-bold text-white">
                      <CountUp
                        start={0}
                        end={98}
                        delay={0}
                        duration={3}
                        decimals={1}
                        suffix="%"
                      >
                        {({ countUpRef }) => (
                          <div>
                            <span ref={countUpRef} />
                          </div>
                        )}
                      </CountUp>
                    </h3>
                    <span className="text-bluegray">
                      stolen cars successfully recovered
                    </span>
                  </div>
                  <div className="mt-4 md:mt-0">
                    <h3 className="text-4xl font-bold text-white">
                      <CountUp
                        start={0}
                        end={82}
                        delay={0}
                        duration={3}
                        decimals={0}
                        suffix=" Minutes"
                      >
                        {({ countUpRef }) => (
                          <div>
                            <span ref={countUpRef} />
                          </div>
                        )}
                      </CountUp>
                    </h3>
                    <span className="text-bluegray">
                      average time to recover stolen vehicles
                    </span>
                  </div>
                  <div className="mt-4 md:mt-0">
                    <h3 className="text-4xl font-bold text-white">
                      <CountUp
                        start={0}
                        end={320}
                        delay={0}
                        duration={3}
                        suffix="+"
                      >
                        {({ countUpRef }) => (
                          <div>
                            <span ref={countUpRef} />
                          </div>
                        )}
                      </CountUp>
                    </h3>
                    <span className="text-bluegray">
                      recovered vehicles since 2014
                    </span>
                  </div>
                </div>
              </div>
              <div className="md:w-2/5">
                <Video
                  videoSrcURL="https://www.youtube.com/embed/a2ylQNgA60Y"
                  videoTitle="KATSANA - Pelacak GPS Terbaik di Malaysia"
                />
                <p className="w-full mx-auto mt-2 text-sm text-center text-bluegray">
                  Astro Awani - Is this the best GPS tracker in Malaysia?
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="relative bg-gradient-to-b from-[hsla(216,59%,30%,1)] to-[hsla(216,69%,40%,1)] pb-24">
          <div className="absolute bottom-0 left-0 w-full">
            <div
              className={
                Styles.tilt +
                " after:bg-[hsla(207,27%,94%,1)] m-[-1px] after:h-[4vh] md:after:h-[10vh] z-20 "
              }
            ></div>
          </div>

          <div className="max-w-6xl px-8 pt-16 mx-auto text-center md:px-0">
            <h3 className="text-4xl font-bold text-white">
              Features of the best car tracking system
            </h3>
            <p className="mt-4 text-sm leading-6 text-bluegray md:text-base md:leading-8">
              Intelligent alerts to protect and monitor the safety of your
              vehicles
            </p>
          </div>

          <MobileView>
            <LazyLoad>
              <PhoneSlider />
            </LazyLoad>
          </MobileView>

          <BrowserView>
            <LazyLoad>
              <GPSTab />
            </LazyLoad>
          </BrowserView>
        </div>

        <div className="relative bg-[hsla(207,27%,94%,1)] pb-48">
          <div className="absolute bottom-0 left-0 w-full">
            <div
              className={
                Styles.tilt +
                " after:bg-[hsla(203,28%,82%,1)] m-[-1px] after:h-[4vh] md:after:h-[10vh] z-20 "
              }
            ></div>
          </div>

          <div className="max-w-4xl p-8 pt-16 mx-auto text-center md:px-0">
            <h3 className="text-xl font-bold md:text-4xl text-bluetext">
              The Best GPS Tracking System in Malaysia with most successful
              recoveries of stolen cars
            </h3>
            <p className="max-w-2xl mx-auto mt-4 text-sm font-medium leading-5 text-bluetext md:text-base md:leading-6">
              Read our past experiences in helping our customers to track,
              locate and recover their stolen vehicles to convince you why
              KATSANA is the best option for protecting your vehicles and assets
              against theft.
            </p>
          </div>
          <CaseSlider />
        </div>

        <div className="relative bg-[hsla(203,28%,82%,1)] pb-36 md:pb-48">
          <div className="flex flex-col max-w-6xl px-8 py-8 mx-auto md:items-center md:flex-row md:px-0">
            <div className="md:max-w-4xl">
              <h3 className="text-4xl font-bold text-bluetext">
                Reviews of the best GPS Trackers in Malaysia
              </h3>
              <p className="max-w-3xl mt-4 text-sm leading-6 md:text-base text-bluetext">
                Our customers love KATSANA. Some of them have been trusting
                KATSANA to secure and track their vehicles for almost half a
                decade by now.
              </p>
              <p className="max-w-3xl mt-4 text-sm leading-6 md:text-base text-bluetext">
                Read what they have to say about our quality of service and
                reliability of the GPS Fleet and car tracking system.
              </p>
            </div>
            <div className="md:ml-16">
              <div className="flex flex-col mt-8 md:mt-0 md:text-center">
                <a
                  href="https://apps.katsana.com/get-quotation/"
                  className="self-start px-5 py-2 font-semibold text-white rounded-full md:self-center md:px-12 md:py-3 md:text-base leading-0 bg-primary"
                >
                  Buy Now
                </a>
                <a
                  href="https://apps.katsana.com/get-quotation/"
                  className="mt-4 text-sm text-primary md:flex"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  or contact us for information
                </a>
              </div>
            </div>
          </div>
          <LazyLoad>
            <ReviewSlider />
          </LazyLoad>

          <div className="mx-auto max-w-primary">
            <Parallax y={[-40, 5]} tagOuter="figure">
              <StaticImage
                alt="katsana"
                src="../../static/images/photos/gpsbackground.jpg"
                objectFit="cover"
                className="relative z-0 md:mt-[-200px] mt-[-100px] h-[150px] md:h-auto"
              />
            </Parallax>
          </div>
        </div>

        <Cta />
      </Layout>
    </ParallaxProvider>
  );
};

export default GPSTracker;
